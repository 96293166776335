<template>
  <trac-modal size="max-w-2xl" @close="$emit('close')">
    <div class="p-4 mt-10 mr-10">
      <h1 class="text-xl font-semibold">Traction Loan Criteria</h1>
      <p class="text-gray-700 mt-10 text-sm">Transactions</p>
      <div class="shadow p-3 rounded mt-2">
        <h1 class="text-gray-900 text-lg font-semibold mt-2">
          Completed at least 25 Transactions over the last 30 days.
          <span class="text-sm font-light text-gray-700">
            Minimum Transaction (N{{ content.minimumAmount }})
          </span>
        </h1>

        <p
          v-if="content.eligible"
          class="text-green-500 text-right font-medium mt-2"
        >
          Completed
        </p>
        <p
          v-else
          class="text-secondaryYellow text-right text-sm font-medium mt-2"
        >
          Pending
        </p>
      </div>
      <p class="text-gray-700 mt-10 text-sm mb-5">
        Transactions (last {{ content.period }} days)
      </p>
      <div class="flex justify-between items-center">
        <p class="text-gray-700 mt-10 text-xs mb-5">Transaction</p>
        <p>{{ content.actualCount }} / {{ content.minimumCount }}</p>
      </div>
      <range-slider
        :class="!content.eligible ? 'warning' : 'slider'"
        class="warning mt-3 mb-6 w-full"
        min="0"
        :disabled="true"
        max="60"
        step="10"
        v-model="content.actualCount"
      >
      </range-slider>
    </div>
  </trac-modal>
</template>

<script>
import RangeSlider from "vue-range-slider";
import "vue-range-slider/dist/vue-range-slider.css";
export default {
  components: {
    RangeSlider,
  },
  props: ["content"],
  data() {
    return {};
  },
};
</script>
