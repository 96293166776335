<template>
  <trac-modal size="max-w-2xl" @close="$emit('close')">
    <div class="p-4 mt-10 mr-10">
      <h1 class="text-xl font-semibold">Traction Loan Criteria</h1>
      <p class="text-gray-700 mt-10 text-sm">Transactions</p>
      <div class="shadow p-3 rounded mt-2">
        <h1 class="text-gray-900 text-lg font-semibold mt-2">
          Completed an average Value of N100,000 in Transactions per month
          (minimum of {{ content.period }} days)
        </h1>

        <p
          v-if="content.eligible"
          class="text-green-500 text-right font-medium mt-2"
        >
          Completed
        </p>
        <p
          v-else
          class="text-secondaryYellow text-right text-sm font-medium mt-2"
        >
          Pending
        </p>
      </div>
      <p class="text-gray-700 mt-10 text-sm mb-5">
        Averaged Transaction last {{ content.period }} days
      </p>
      <div class="flex justify-between items-center">
        <p class="text-gray-700 text-xs mb-5">Transaction</p>
        <p>{{ content.actualValue | formatPrice }}</p>
      </div>
      <range-slider
        :class="!content.eligible ? 'warning' : 'slider'"
        class="warning mt-3 mb-6 w-full"
        min="0"
        :disabled="true"
        max="60"
        step="100000"
        v-model="content.actualValue"
      >
      </range-slider>

      <p class="text-gray-700 text-xs mb-5">Recent Transaction</p>
      <div>
        <div class="flex justify-between items-center">
          <div class="flex items-center">
            <input
              checked
              type="radio"
              name=""
              id=""
              class="w-5 h-5"
              :class="
                content.last30DaysValue >= 100000
                  ? 'loan-radio-success'
                  : 'loan-radio-warning'
              "
            />
            <p class="ml-2">Last 30 Days</p>
          </div>

          <div>{{ content.last30DaysValue | formatPrice }}</div>
        </div>
        <div class="flex justify-between items-center mt-3">
          <div class="flex items-center">
            <input
              checked
              type="radio"
              name=""
              id=""
              class="w-5 h-5"
              :class="
                content.last31to60DaysValue >= 100000
                  ? 'loan-radio-success'
                  : 'loan-radio-warning'
              "
            />
            <p class="ml-2">30 - 60 Days</p>
          </div>
          <div>{{ content.last31to60DaysValue | formatPrice }}</div>
        </div>
      </div>
    </div>
  </trac-modal>
</template>

<script>
import RangeSlider from "vue-range-slider";
import "vue-range-slider/dist/vue-range-slider.css";
export default {
  components: {
    RangeSlider,
  },
  props: ["content"],
  data() {
    return {};
  },
  computed: {
    total() {},
  },
};
</script>

<style>
.loan-radio-warning[type="radio"] {
  accent-color: #fbd56f;
}
.loan-radio-success[type="radio"] {
  accent-color: #07912e;
}
</style>
