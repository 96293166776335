<template>
  <trac-modal size="max-w-2xl" @close="$emit('close')">
    <div class="p-4 mt-10 mr-10 mb-32">
      <h1 class="text-xl font-semibold">Wallet</h1>
      <p class="text-gray-700 mt-10 text-sm">Transactions</p>
      <div class="shadow p-3 rounded mt-2">
        <h1 class="text-gray-900 text-lg font-semibold mt-2">
          Merchant Wallet Creation
        </h1>
        <p
          v-if="content.eligible"
          class="text-green-500 text-right font-medium mt-2"
        >
          Completed
        </p>
        <p
          v-else
          class="text-secondaryYellow text-right text-sm font-medium mt-2"
        >
          Pending
        </p>
      </div>
      <div v-if="!content.eligible">
        <div
          class="bg-yellow-100 text-secondaryYellow border text-center border-secondaryYellow py-2 rounded px-2 text-sm mt-8"
        >
          A merchant wallet is needed in order for Traction to deposit your loan
        </div>

        <div class="mx-auto flex justify-center items-center mt-16">
          <trac-button @click.native="$router.push({ name: 'MainWallet' })">
            <div class="py-2 px-12 text-sm">Create a Merchant Wallet</div>
          </trac-button>
        </div>
      </div>
    </div>
  </trac-modal>
</template>

<script>
export default {
  props: ["content"],
};
</script>
