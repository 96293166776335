<template>
  <trac-modal size="max-w-2xl" @close="$emit('close')">
    <div class="p-4 mt-10 mr-10">
      <div v-if="content.eligible">
        <h1 class="text-xl font-semibold">Traction Loan Criteria</h1>
        <p class="text-gray-700 mt-10 text-sm">Traction Registration</p>
        <div class="shadow p-3 rounded mt-2">
          <h1 class="text-gray-900 text-lg font-semibold mt-2">
            Registered a POS terminal or fixed account with Traction
          </h1>
          <p class="text-green-500 text-right font-medium mt-2">Completed</p>
        </div>
        <p class="text-gray-700 mt-10 text-sm">
          Time registered with Traction payment
        </p>
        <div class="shadow p-3 rounded mt-2">
          <h1 class="text-gray-900 text-lg font-semibold mt-2">
            {{ content.daysSinceRegisteredPosOrFixedAccount }} / 60 days
          </h1>
        </div>
        <div>
          <div class="flex w-full justify-between">
            <div class="flex flex-col items-center">
              <p class="text-gray-700 mt-10 mb-1 text-sm">Registration Date</p>
              <div>
                {{
                  moment(content.dateRegisteredPosOrFixedAccount).format(
                    "DD MMMM, YYYY"
                  )
                }}
              </div>
            </div>
            <div>
              <div class="flex flex-col items-center">
                <p class="text-gray-700 mt-10 mb-1 text-sm">Completion Date</p>
                <div>
                  {{ moment(content.eligibleDate).format("DD MMMM, YYYY") }}
                </div>
              </div>
            </div>
          </div>
          <range-slider
            class="slider mt-3 mb-10 w-full"
            min="0"
            :disabled="true"
            max="60"
            step="10"
            v-model="content.daysSinceRegisteredPosOrFixedAccount"
          >
          </range-slider>
        </div>
      </div>
      <div v-else>
        <h1 class="text-xl font-semibold">Traction Loan Criteria</h1>
        <p class="text-gray-700 mt-10 text-sm">Traction Registration</p>
        <div class="shadow p-3 rounded mt-2">
          <h1 class="text-gray-900 text-lg font-semibold mt-2">
            Registered a POS terminal or fixed account with Traction
          </h1>
          <p class="text-secondaryYellow text-right text-sm font-medium mt-2">
            Pending
          </p>
        </div>
        <p class="text-gray-700 mt-10 text-sm">
          Time registered with Traction payment
        </p>
        <div class="shadow p-3 rounded mt-2">
          <h1 class="text-gray-900 text-lg font-semibold mt-2">
            {{ content.daysSinceRegisteredPosOrFixedAccount }} / 60 days
          </h1>
        </div>
        <div>
          <div class="flex w-full justify-between">
            <div class="flex flex-col items-center">
              <p class="text-gray-700 mt-10 mb-1 text-sm">Registration Date</p>
              <div v-if="content.dateRegisteredPosOrFixedAccount === null">
                {{ moment().format("DD MMMM, YYYY") }}
              </div>
              <div v-else>
                {{
                  moment(content.dateRegisteredPosOrFixedAccount).format(
                    "DD MMMM, YYYY"
                  )
                }}
              </div>
            </div>
            <div>
              <div class="flex flex-col items-center">
                <p class="text-gray-700 mt-10 mb-1 text-sm">Completion Date</p>
                <div>
                  {{ moment(content.eligibleDate).format("DD MMMM, YYYY") }}
                </div>
              </div>
            </div>
          </div>
          <range-slider
            class="warning mt-3 mb-6 w-full"
            min="0"
            :disabled="true"
            max="60"
            step="10"
            v-model="content.daysSinceRegisteredPosOrFixedAccount"
          >
          </range-slider>
        </div>
        <div class="flex justify-center items-center flex-col">
          <router-link
            class="text-primaryBlue underline font-medium"
            to="/fixed-account"
          >
            Create a Fixed account
          </router-link>
          <router-link
            class="text-primaryBlue underline font-medium mt-2"
            to="/terminal-request"
          >
            Request a POS Terminal
          </router-link>
        </div>
      </div>
    </div>
  </trac-modal>
</template>

<script>
import RangeSlider from "vue-range-slider";
import "vue-range-slider/dist/vue-range-slider.css";
import moment from "moment";

export default {
  components: {
    RangeSlider,
  },
  props: ["content"],
  data() {
    return {
      moment,
    };
  },
};
</script>

<style>
.slider .range-slider-rail,
.range-slider-fill {
  display: block;
  position: absolute;
  top: 50%;
  left: 0;
  height: 10px;
  border-radius: 4px;
  transform: translateY(-50%);
}

.slider {
  width: 100%;
}

.slider .range-slider-rail {
  width: 100%;
  background-color: #07912e;
}
.slider .range-slider-fill {
  background-color: #07912e;
}

.slider.range-slider.disabled {
  opacity: 1;
}
.slider.range-slider-knob {
  background-color: #07912e;
}

.warning .range-slider-rail,
.range-slider-fill {
  display: block;
  position: absolute;
  top: 50%;
  left: 0;
  height: 10px;
  border-radius: 4px;
  transform: translateY(-50%);
}

.warning {
  width: 100%;
}

.warning .range-slider-rail {
  width: 100%;
  background-color: #fbd66f81;
}
.warning .range-slider-fill {
  background-color: #fbd56f;
}

.warning.range-slider.disabled {
  opacity: 1;
}
.warning.range-slider-knob {
  background-color: #fbd56f;
}
</style>
