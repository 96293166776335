<template>
  <trac-modal size="max-w-2xl" @close="$emit('close')">
    <div class="p-4 mt-10 mr-10">
      <h1 class="text-xl font-semibold">Traction Loan Criteria</h1>
      <p class="text-gray-700 mt-10 text-sm">Kyc Registration</p>
      <div class="shadow p-3 rounded mt-2">
        <h1 class="text-gray-900 text-lg font-semibold mt-2">
          Completed all KYC Registration
        </h1>
        <p
          v-if="content.eligible"
          class="text-green-500 text-right font-medium mt-2"
        >
          Completed
        </p>
        <p
          v-else
          class="text-secondaryYellow text-right text-sm font-medium mt-2"
        >
          Pending
        </p>
      </div>
      <p class="text-gray-700 mt-10 text-sm mb-5">Kyc Status</p>

      <div class="flex justify-between items-center mb-3">
        <div>Email Verification</div>
        <div>
          <svg
            v-if="!content.emailVerified"
            width="27"
            height="27"
            viewBox="0 0 27 27"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M21.375 7.21125L19.7888 5.625L13.5 11.9138L7.21125 5.625L5.625 7.21125L11.9138 13.5L5.625 19.7888L7.21125 21.375L13.5 15.0863L19.7888 21.375L21.375 19.7888L15.0863 13.5L21.375 7.21125Z"
              fill="#F80300"
            />
          </svg>
          <svg
            v-else
            width="27"
            height="27"
            viewBox="0 0 27 27"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M13.5 2.25C7.29 2.25 2.25 7.29 2.25 13.5C2.25 19.71 7.29 24.75 13.5 24.75C19.71 24.75 24.75 19.71 24.75 13.5C24.75 7.29 19.71 2.25 13.5 2.25ZM13.5 22.5C8.53875 22.5 4.5 18.4613 4.5 13.5C4.5 8.53875 8.53875 4.5 13.5 4.5C18.4613 4.5 22.5 8.53875 22.5 13.5C22.5 18.4613 18.4613 22.5 13.5 22.5ZM11.25 15.9413L17.865 9.32625C18.3037 8.8875 19.0238 8.8875 19.4625 9.32625C19.9013 9.765 19.9013 10.4738 19.4625 10.9125L12.0488 18.3263C11.61 18.765 10.9012 18.765 10.4625 18.3263L7.54875 15.4125C7.11 14.9738 7.11 14.265 7.54875 13.8263C7.75894 13.6156 8.04429 13.4972 8.34188 13.4972C8.63946 13.4972 8.92482 13.6156 9.135 13.8263L11.25 15.9413Z"
              fill="#07912E"
            />
          </svg>
        </div>
      </div>
      <div class="flex justify-between items-center mb-3">
        <div>Phone Verification</div>

        <div>
          <svg
            v-if="!content.phoneVerified"
            width="27"
            height="27"
            viewBox="0 0 27 27"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M21.375 7.21125L19.7888 5.625L13.5 11.9138L7.21125 5.625L5.625 7.21125L11.9138 13.5L5.625 19.7888L7.21125 21.375L13.5 15.0863L19.7888 21.375L21.375 19.7888L15.0863 13.5L21.375 7.21125Z"
              fill="#F80300"
            />
          </svg>
          <svg
            v-else
            width="27"
            height="27"
            viewBox="0 0 27 27"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M13.5 2.25C7.29 2.25 2.25 7.29 2.25 13.5C2.25 19.71 7.29 24.75 13.5 24.75C19.71 24.75 24.75 19.71 24.75 13.5C24.75 7.29 19.71 2.25 13.5 2.25ZM13.5 22.5C8.53875 22.5 4.5 18.4613 4.5 13.5C4.5 8.53875 8.53875 4.5 13.5 4.5C18.4613 4.5 22.5 8.53875 22.5 13.5C22.5 18.4613 18.4613 22.5 13.5 22.5ZM11.25 15.9413L17.865 9.32625C18.3037 8.8875 19.0238 8.8875 19.4625 9.32625C19.9013 9.765 19.9013 10.4738 19.4625 10.9125L12.0488 18.3263C11.61 18.765 10.9012 18.765 10.4625 18.3263L7.54875 15.4125C7.11 14.9738 7.11 14.265 7.54875 13.8263C7.75894 13.6156 8.04429 13.4972 8.34188 13.4972C8.63946 13.4972 8.92482 13.6156 9.135 13.8263L11.25 15.9413Z"
              fill="#07912E"
            />
          </svg>
        </div>
      </div>
      <div class="flex justify-between items-center mb-3">
        <div>Contact Verification</div>
        <div>
          <svg
            v-if="!content.identityVerified"
            width="27"
            height="27"
            viewBox="0 0 27 27"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M21.375 7.21125L19.7888 5.625L13.5 11.9138L7.21125 5.625L5.625 7.21125L11.9138 13.5L5.625 19.7888L7.21125 21.375L13.5 15.0863L19.7888 21.375L21.375 19.7888L15.0863 13.5L21.375 7.21125Z"
              fill="#F80300"
            />
          </svg>
          <svg
            v-else
            width="27"
            height="27"
            viewBox="0 0 27 27"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M13.5 2.25C7.29 2.25 2.25 7.29 2.25 13.5C2.25 19.71 7.29 24.75 13.5 24.75C19.71 24.75 24.75 19.71 24.75 13.5C24.75 7.29 19.71 2.25 13.5 2.25ZM13.5 22.5C8.53875 22.5 4.5 18.4613 4.5 13.5C4.5 8.53875 8.53875 4.5 13.5 4.5C18.4613 4.5 22.5 8.53875 22.5 13.5C22.5 18.4613 18.4613 22.5 13.5 22.5ZM11.25 15.9413L17.865 9.32625C18.3037 8.8875 19.0238 8.8875 19.4625 9.32625C19.9013 9.765 19.9013 10.4738 19.4625 10.9125L12.0488 18.3263C11.61 18.765 10.9012 18.765 10.4625 18.3263L7.54875 15.4125C7.11 14.9738 7.11 14.265 7.54875 13.8263C7.75894 13.6156 8.04429 13.4972 8.34188 13.4972C8.63946 13.4972 8.92482 13.6156 9.135 13.8263L11.25 15.9413Z"
              fill="#07912E"
            />
          </svg>
        </div>
      </div>
    </div>
  </trac-modal>
</template>

<script>
export default {
  props: ["content"],
  data() {
    return {};
  },
};
</script>
