<template>
  <trac-loading v-if="loading" />
  <main v-else>
    <div class="blue_container flex justify-between px-10 py-8 rounded-md">
      <div>
        <h2 class="text-white text-2xl">
          Do you need Capital to move your business forward
        </h2>
        <div class="text-white mt-1">Apply for a Traction loan Today</div>
      </div>
      <div class="blue-container__img">
        <img src="@/assets/svg/loan-landing.svg" class="w-40 h-40" alt="" />
      </div>
    </div>
    <div
      :class="textClass"
      class="max-w-5xl mx-auto w-full mt-12 py-4 px-2 border text-sm text-center rounded"
    >
      {{ text.description }}
    </div>
    <div
      class="max-w-5xl mx-auto w-full mt-12 rounded-md bg-white loan-shadow px-8 py-10"
    >
      <div
        @click="toggleModal()"
        class="loan-shadow rounded-md p-5 flex justify-between items-cente relative"
      >
        <div>
          <div class="font-bold text-gray-800">
            Registered a POS terminal or fixed account with Traction
          </div>
          <div class="text-sm w-4/5 mt-2 text-gray-600">
            To access Traction’s loan, you must have registered a POS Terminal
            or Fixed account with Traction for at least 2 Months (60 days)
          </div>
        </div>
        <div>
          <p
            v-if="loanStatus.membershipInfo.eligible"
            class="text-green-500 mt-6 text-sm"
          >
            Completed
          </p>
          <div v-else>
            <p class="text-secondaryYellow text-sm mt-3">Pending</p>

            <div class="absolute right-0 mr-10 mt-2">
              <svg
                class="w-4 h-4"
                viewBox="0 0 17 33"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1 32L16 16.5L1 1"
                  stroke="#013E9E"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
          </div>
        </div>
        <div class="bg-white absolute left-0 ml-5 -mt-10 p-2">
          <svg
            class=" "
            width="24"
            height="19"
            viewBox="0 0 24 19"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M8.136 18.288C6.744 18.288 5.504 17.944 4.416 17.256C3.328 16.568 2.472 15.576 1.848 14.28C1.24 12.968 0.936 11.408 0.936 9.6C0.936 7.792 1.24 6.24 1.848 4.944C2.472 3.632 3.328 2.632 4.416 1.944C5.504 1.256 6.744 0.912 8.136 0.912C9.528 0.912 10.768 1.256 11.856 1.944C12.944 2.632 13.792 3.632 14.4 4.944C15.024 6.24 15.336 7.792 15.336 9.6C15.336 11.408 15.024 12.968 14.4 14.28C13.792 15.576 12.944 16.568 11.856 17.256C10.768 17.944 9.528 18.288 8.136 18.288ZM8.136 15C9.16 15 9.96 14.56 10.536 13.68C11.128 12.8 11.424 11.44 11.424 9.6C11.424 7.76 11.128 6.4 10.536 5.52C9.96 4.64 9.16 4.2 8.136 4.2C7.128 4.2 6.328 4.64 5.736 5.52C5.16 6.4 4.872 7.76 4.872 9.6C4.872 11.44 5.16 12.8 5.736 13.68C6.328 14.56 7.128 15 8.136 15ZM23.5644 1.2V18H19.6764V4.32H16.3164V1.2H23.5644Z"
              fill="url(#paint0_linear_6191_12822)"
            />
            <defs>
              <linearGradient
                id="paint0_linear_6191_12822"
                x1="13"
                y1="-5"
                x2="13"
                y2="24"
                gradientUnits="userSpaceOnUse"
              >
                <stop offset="0.369792" stop-color="#6C63FF" />
                <stop offset="0.572917" stop-color="#013E9E" />
              </linearGradient>
            </defs>
          </svg>
        </div>
      </div>
      <div
        @click="isKycModal = true"
        class="loan-shadow rounded-md p-5 flex justify-between relative mt-10"
      >
        <div>
          <div class="font-bold text-gray-800">
            Completed all KYC Requirements
          </div>
          <div class="text-sm w-4/5 mt-2 text-gray-600">
            You must have completed all KYC registration requirements to access
            Traction credit loan
          </div>
        </div>
        <div>
          <p v-if="loanStatus.kyc.eligible" class="text-green-500 mt-6">
            Completed
          </p>
          <div v-else>
            <p class="text-secondaryYellow text-sm mt-3">Pending</p>
            <div class="absolute right-0 mr-10 mt-2">
              <svg
                class="w-4 h-4"
                viewBox="0 0 17 33"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1 32L16 16.5L1 1"
                  stroke="#013E9E"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
          </div>
        </div>
        <div class="bg-white absolute left-0 ml-5 -mt-10 p-2">
          <svg
            width="30"
            height="19"
            viewBox="0 0 30 19"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M8.136 18.288C6.744 18.288 5.504 17.944 4.416 17.256C3.328 16.568 2.472 15.576 1.848 14.28C1.24 12.968 0.936 11.408 0.936 9.6C0.936 7.792 1.24 6.24 1.848 4.944C2.472 3.632 3.328 2.632 4.416 1.944C5.504 1.256 6.744 0.912 8.136 0.912C9.528 0.912 10.768 1.256 11.856 1.944C12.944 2.632 13.792 3.632 14.4 4.944C15.024 6.24 15.336 7.792 15.336 9.6C15.336 11.408 15.024 12.968 14.4 14.28C13.792 15.576 12.944 16.568 11.856 17.256C10.768 17.944 9.528 18.288 8.136 18.288ZM8.136 15C9.16 15 9.96 14.56 10.536 13.68C11.128 12.8 11.424 11.44 11.424 9.6C11.424 7.76 11.128 6.4 10.536 5.52C9.96 4.64 9.16 4.2 8.136 4.2C7.128 4.2 6.328 4.64 5.736 5.52C5.16 6.4 4.872 7.76 4.872 9.6C4.872 11.44 5.16 12.8 5.736 13.68C6.328 14.56 7.128 15 8.136 15ZM29.6364 14.832V18H16.9404V15.48L23.4204 9.36C24.1084 8.704 24.5724 8.144 24.8124 7.68C25.0524 7.2 25.1724 6.728 25.1724 6.264C25.1724 5.592 24.9404 5.08 24.4764 4.728C24.0284 4.36 23.3644 4.176 22.4844 4.176C21.7484 4.176 21.0844 4.32 20.4924 4.608C19.9004 4.88 19.4044 5.296 19.0044 5.856L16.1724 4.032C16.8284 3.056 17.7324 2.296 18.8844 1.752C20.0364 1.192 21.3564 0.912 22.8444 0.912C24.0924 0.912 25.1804 1.12 26.1084 1.536C27.0524 1.936 27.7804 2.512 28.2924 3.264C28.8204 4 29.0844 4.872 29.0844 5.88C29.0844 6.792 28.8924 7.648 28.5084 8.448C28.1244 9.248 27.3804 10.16 26.2764 11.184L22.4124 14.832H29.6364Z"
              fill="url(#paint0_linear_6191_12831)"
            />
            <defs>
              <linearGradient
                id="paint0_linear_6191_12831"
                x1="15.5"
                y1="-5"
                x2="15.5"
                y2="24"
                gradientUnits="userSpaceOnUse"
              >
                <stop offset="0.369792" stop-color="#6C63FF" />
                <stop offset="0.572917" stop-color="#013E9E" />
              </linearGradient>
            </defs>
          </svg>
        </div>
      </div>
      <div
        @click="isMinTransactionModal = true"
        class="loan-shadow rounded-md p-5 flex justify-between relative mt-10"
      >
        <div>
          <div class="font-bold text-gray-800">
            Completed 25 Transactions over the last 30 days
          </div>
          <div class="text-sm w-4/5 mt-2 text-gray-600">
            Complete a minimum of 25 Transaction over a 30 day period. Minimum
            amount of Transaction (NGN 150) . Transaction includes bank
            transfers, Airtime Purchase, etc.
          </div>
        </div>
        <div>
          <p
            v-if="loanStatus.transactionsCount.eligible"
            class="text-green-500 mt-6"
          >
            Completed
          </p>
          <div v-else>
            <p class="text-secondaryYellow text-sm mt-3">Pending</p>
            <div class="absolute right-0 mr-10 mt-2">
              <svg
                class="w-4 h-4"
                viewBox="0 0 17 33"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1 32L16 16.5L1 1"
                  stroke="#013E9E"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
          </div>
        </div>
        <div class="bg-white absolute left-0 ml-5 -mt-10 p-2">
          <svg
            width="30"
            height="19"
            viewBox="0 0 30 19"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M8.136 18.288C6.744 18.288 5.504 17.944 4.416 17.256C3.328 16.568 2.472 15.576 1.848 14.28C1.24 12.968 0.936 11.408 0.936 9.6C0.936 7.792 1.24 6.24 1.848 4.944C2.472 3.632 3.328 2.632 4.416 1.944C5.504 1.256 6.744 0.912 8.136 0.912C9.528 0.912 10.768 1.256 11.856 1.944C12.944 2.632 13.792 3.632 14.4 4.944C15.024 6.24 15.336 7.792 15.336 9.6C15.336 11.408 15.024 12.968 14.4 14.28C13.792 15.576 12.944 16.568 11.856 17.256C10.768 17.944 9.528 18.288 8.136 18.288ZM8.136 15C9.16 15 9.96 14.56 10.536 13.68C11.128 12.8 11.424 11.44 11.424 9.6C11.424 7.76 11.128 6.4 10.536 5.52C9.96 4.64 9.16 4.2 8.136 4.2C7.128 4.2 6.328 4.64 5.736 5.52C5.16 6.4 4.872 7.76 4.872 9.6C4.872 11.44 5.16 12.8 5.736 13.68C6.328 14.56 7.128 15 8.136 15ZM24.8387 8.04C26.3107 8.28 27.4387 8.832 28.2227 9.696C29.0067 10.544 29.3987 11.6 29.3987 12.864C29.3987 13.84 29.1427 14.744 28.6307 15.576C28.1187 16.392 27.3347 17.048 26.2787 17.544C25.2387 18.04 23.9587 18.288 22.4387 18.288C21.2547 18.288 20.0867 18.136 18.9347 17.832C17.7987 17.512 16.8307 17.064 16.0307 16.488L17.5427 13.512C18.1827 13.992 18.9187 14.368 19.7507 14.64C20.5987 14.896 21.4627 15.024 22.3427 15.024C23.3187 15.024 24.0867 14.84 24.6467 14.472C25.2067 14.088 25.4867 13.552 25.4867 12.864C25.4867 11.488 24.4387 10.8 22.3427 10.8H20.5667V8.232L24.0227 4.32H16.8707V1.2H28.6307V3.72L24.8387 8.04Z"
              fill="url(#paint0_linear_6191_12841)"
            />
            <defs>
              <linearGradient
                id="paint0_linear_6191_12841"
                x1="15.5"
                y1="-5"
                x2="15.5"
                y2="24"
                gradientUnits="userSpaceOnUse"
              >
                <stop offset="0.369792" stop-color="#6C63FF" />
                <stop offset="0.572917" stop-color="#013E9E" />
              </linearGradient>
            </defs>
          </svg>
        </div>
      </div>
      <div
        @click="isAverageTransactionModal = true"
        class="loan-shadow rounded-md p-5 flex justify-between relative mt-10"
      >
        <div>
          <div class="font-bold text-gray-800">
            Completed an average of N100,000 in Transactions per month
          </div>
          <div class="text-sm w-4/5 mt-2 text-gray-600">
            Complete an average value of N100,000 in Transactions per month
            within a minimum 60 days .Transaction includes bank transfers,
            Airtime Purchase, etc.
          </div>
        </div>
        <div>
          <p
            v-if="loanStatus.transactionsTotalValue.eligible"
            class="text-green-500"
          >
            Completed
          </p>
          <div v-else>
            <p class="text-secondaryYellow text-sm mt-3">Pending</p>
            <div class="absolute right-0 mr-10 mt-2">
              <svg
                class="w-4 h-4"
                viewBox="0 0 17 33"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1 32L16 16.5L1 1"
                  stroke="#013E9E"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
          </div>
        </div>
        <div class="bg-white absolute left-0 ml-5 -mt-10 p-2">
          <svg
            width="33"
            height="19"
            viewBox="0 0 33 19"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M8.136 18.288C6.744 18.288 5.504 17.944 4.416 17.256C3.328 16.568 2.472 15.576 1.848 14.28C1.24 12.968 0.936 11.408 0.936 9.6C0.936 7.792 1.24 6.24 1.848 4.944C2.472 3.632 3.328 2.632 4.416 1.944C5.504 1.256 6.744 0.912 8.136 0.912C9.528 0.912 10.768 1.256 11.856 1.944C12.944 2.632 13.792 3.632 14.4 4.944C15.024 6.24 15.336 7.792 15.336 9.6C15.336 11.408 15.024 12.968 14.4 14.28C13.792 15.576 12.944 16.568 11.856 17.256C10.768 17.944 9.528 18.288 8.136 18.288ZM8.136 15C9.16 15 9.96 14.56 10.536 13.68C11.128 12.8 11.424 11.44 11.424 9.6C11.424 7.76 11.128 6.4 10.536 5.52C9.96 4.64 9.16 4.2 8.136 4.2C7.128 4.2 6.328 4.64 5.736 5.52C5.16 6.4 4.872 7.76 4.872 9.6C4.872 11.44 5.16 12.8 5.736 13.68C6.328 14.56 7.128 15 8.136 15ZM32.6091 14.472H29.8731V18H26.0811V14.472H17.0331V11.856L24.9531 1.2H29.0331L21.6891 11.304H26.2011V8.16H29.8731V11.304H32.6091V14.472Z"
              fill="url(#paint0_linear_6191_12851)"
            />
            <defs>
              <linearGradient
                id="paint0_linear_6191_12851"
                x1="16.5"
                y1="-5"
                x2="16.5"
                y2="24"
                gradientUnits="userSpaceOnUse"
              >
                <stop offset="0.369792" stop-color="#6C63FF" />
                <stop offset="0.572917" stop-color="#013E9E" />
              </linearGradient>
            </defs>
          </svg>
        </div>
      </div>
      <div
        @click="isWalletModal = true"
        class="loan-shadow rounded-md p-5 flex justify-between relative mt-10"
      >
        <div>
          <div class="font-bold text-gray-800">
            Have an active Traction Merchant Wallet
          </div>
          <div class="text-sm w-4/5 mt-2 text-gray-600">
            A merchant wallet is needed in order for Traction to deposit your
            loan
          </div>
        </div>
        <div>
          <p
            v-if="loanStatus.wallet.eligible"
            class="text-green-500 mt-6 text-sm"
          >
            Completed
          </p>
          <div v-else>
            <p class="text-secondaryYellow text-sm mt-3">Pending</p>
            <div class="absolute right-0 mr-10 mt-2">
              <svg
                class="w-4 h-4"
                viewBox="0 0 17 33"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1 32L16 16.5L1 1"
                  stroke="#013E9E"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
          </div>
        </div>
        <div class="bg-white absolute left-0 ml-5 -mt-10 p-2">
          <svg
            width="30"
            height="19"
            viewBox="0 0 30 19"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M8.136 18.288C6.744 18.288 5.504 17.944 4.416 17.256C3.328 16.568 2.472 15.576 1.848 14.28C1.24 12.968 0.936 11.408 0.936 9.6C0.936 7.792 1.24 6.24 1.848 4.944C2.472 3.632 3.328 2.632 4.416 1.944C5.504 1.256 6.744 0.912 8.136 0.912C9.528 0.912 10.768 1.256 11.856 1.944C12.944 2.632 13.792 3.632 14.4 4.944C15.024 6.24 15.336 7.792 15.336 9.6C15.336 11.408 15.024 12.968 14.4 14.28C13.792 15.576 12.944 16.568 11.856 17.256C10.768 17.944 9.528 18.288 8.136 18.288ZM8.136 15C9.16 15 9.96 14.56 10.536 13.68C11.128 12.8 11.424 11.44 11.424 9.6C11.424 7.76 11.128 6.4 10.536 5.52C9.96 4.64 9.16 4.2 8.136 4.2C7.128 4.2 6.328 4.64 5.736 5.52C5.16 6.4 4.872 7.76 4.872 9.6C4.872 11.44 5.16 12.8 5.736 13.68C6.328 14.56 7.128 15 8.136 15ZM22.9851 7.512C25.3531 7.512 27.0971 7.984 28.2171 8.928C29.3531 9.872 29.9211 11.136 29.9211 12.72C29.9211 13.744 29.6651 14.68 29.1531 15.528C28.6411 16.36 27.8571 17.032 26.8011 17.544C25.7611 18.04 24.4731 18.288 22.9371 18.288C21.7531 18.288 20.5851 18.136 19.4331 17.832C18.2971 17.512 17.3291 17.064 16.5291 16.488L18.0651 13.512C18.7051 13.992 19.4411 14.368 20.2731 14.64C21.1051 14.896 21.9611 15.024 22.8411 15.024C23.8171 15.024 24.5851 14.832 25.1451 14.448C25.7051 14.064 25.9851 13.528 25.9851 12.84C25.9851 12.12 25.6891 11.576 25.0971 11.208C24.5211 10.84 23.5131 10.656 22.0731 10.656H17.7771L18.6411 1.2H28.8891V4.32H21.8811L21.6171 7.512H22.9851Z"
              fill="url(#paint0_linear_6539_11951)"
            />
            <defs>
              <linearGradient
                id="paint0_linear_6539_11951"
                x1="15.5"
                y1="-5"
                x2="15.5"
                y2="24"
                gradientUnits="userSpaceOnUse"
              >
                <stop offset="0.369792" stop-color="#6C63FF" />
                <stop offset="0.572917" stop-color="#013E9E" />
              </linearGradient>
            </defs>
          </svg>
        </div>
      </div>
      <div class="mt-16 mb-20 justify-center items-center flex">
        <trac-button :disabled="!loanStatus.eligible" @click.native="toggle">
          <div class="py-2 text-sm px-32">Apply for Loan</div>
        </trac-button>
      </div>
      <div class="flex item-center text-sm">
        Don’t have a Traction Wallet?
        <router-link class="ml-1 text-primaryBlue" :to="{ name: 'MainWallet' }">
          Apply here
        </router-link>
      </div>
      <div class="flex item-center text-sm">
        Didn’t qualify for a Traction Loan but want to be considered?
        <router-link
          class="ml-1 text-primaryBlue"
          :to="{ name: 'LoanRequest' }"
        >
          Apply here
        </router-link>
      </div>
    </div>
    <membershipInfoModal
      v-if="ismembershipInfoModal"
      @close="ismembershipInfoModal = false"
      :content="loanStatus.membershipInfo"
    />
    <KycModal
      v-if="isKycModal"
      @close="isKycModal = false"
      :content="loanStatus.kyc"
    />
    <MinTransactionModal
      v-if="isMinTransactionModal"
      @close="isMinTransactionModal = false"
      :content="loanStatus.transactionsCount"
    />
    <AverageTransactionModal
      v-if="isAverageTransactionModal"
      @close="isAverageTransactionModal = false"
      :content="loanStatus.transactionsTotalValue"
    />
    <WalletModal
      v-if="isWalletModal"
      @close="isWalletModal = false"
      :content="loanStatus.wallet"
    />
    <trac-modal v-if="isEmbeddedModal" size=" max-w-5xl" :cancel="false">
      <div class="absolute top-0 mt-3 mr-3 right-0">
        <svg
          @click="isEmbeddedModal = false"
          xmlns="http://www.w3.org/2000/svg"
          class="h-6 w-6 cursor-pointer"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          stroke-width="2"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M6 18L18 6M6 6l12 12"
          />
        </svg>
      </div>
      <div id="form" class="mt-5" style="width: 100%; height: 75vh"></div>
    </trac-modal>
  </main>
</template>

<script>
import membershipInfoModal from "./membershipInfoModal.vue";
import KycModal from "./KycModal.vue";
import MinTransactionModal from "./MinTransactionModal.vue";
import AverageTransactionModal from "./AverageTransactionModal.vue";
import WalletModal from "./WalletModal.vue";

export default {
  components: {
    membershipInfoModal,
    KycModal,
    MinTransactionModal,
    AverageTransactionModal,
    WalletModal,
  },
  data() {
    return {
      loading: false,
      loanStatus: null,
      text: {
        description: "",
        status: "",
      },
      modalContent: "",
      ismembershipInfoModal: false,
      isKycModal: false,
      isMinTransactionModal: false,
      isAverageTransactionModal: false,
      isWalletModal: false,
      isEmbeddedModal: false,
    };
  },
  computed: {
    textClass() {
      if (this.text.status == "success") {
        return "bg-green-100 text-green-400 border-green-400  ";
      } else if (this.text.status == "warning") {
        return "bg-blue-100 text-blue-400 border-blue-400";
      } else {
        return "bg-yellow-100 text-secondaryYellow border-secondaryYellow";
      }
    },
  },
  methods: {
    toggle() {
      this.isEmbeddedModal = true;
      this.$nextTick(() => {
        const container = document.querySelector("#form");
        window.tf.createWidget("jZ6CaAVR", {
          container,
          // hideHeaders: true,
          // hideFooter: true,
          opacity: 1,
          // hidden: {
          //   article_url: window.location.href,
          // },
        });
      });
    },
    async getLoanStatus() {
      try {
        this.loading = true;
        const result = await this.$store.dispatch("GET_LOAN_STATUS");

        this.loanStatus = result.data;

        if (this.loanStatus.eligible) {
          this.text.description = `Congratulations, you are eligible for a Traction loan. Apply now to access funds to power up your business`;
          this.text.status = "success";
          return;
        }

        const everyCheck =
          !this.loanStatus["kyc"].eligible &&
          !this.loanStatus["membershipInfo"].eligible &&
          !this.loanStatus["transactionsCount"].eligible &&
          !this.loanStatus["transactionsTotalValue"].eligible &&
          !this.loanStatus["wallet"].eligible;

        if (everyCheck) {
          this.text.description = `You are close to qualifying for a loan. Simply meet up with the below criteria and apply for a loan.`;
          this.text.status = "warning";
          return;
        }

        if (!this.loanStatus["membershipInfo"].eligible) {
          this.text.description = `You are close to qualifying for a loan. Simply meet up with the below criteria and apply for a loan.`;
          this.text.status = "warning";
          return;
        }

        if (!this.loanStatus["kyc"].eligible) {
          this.text.description = `Complete all KYC requirements in order to be eligible to apply for a Traction Loan`;
          this.text.status = "warning";
          return;
        }

        if (
          !this.loanStatus["transactionsCount"].eligible ||
          !this.loanStatus["transactionsTotalValue"].eligible
        ) {
          this.text.description = `Increase your Transactions on  Traction in order to be eligible to apply for a Traction Loan`;
          this.text.status = "warning";
          return;
        }

        if (!this.loanStatus["wallet"].eligible) {
          this.text.description = `Create a Merchant Wallet  in order to be eligible to apply for a Traction Loan`;
          this.text.status = "warning";
          return;
        }

        return result;
      } catch (e) {
        console.error(e);
      } finally {
        this.loading = false;
      }
    },
    toggleModal() {
      this.ismembershipInfoModal = true;
    },
  },
  async created() {
    await this.getLoanStatus();
  },
};
</script>

<style scoped>
.blue_container {
  background: linear-gradient(105.9deg, #336df5 0%, #0049f3 100%);
}
.loan-shadow {
  box-shadow: 0px 4px 20px rgba(216, 216, 216, 0.25);
  border: 1px solid rgba(216, 216, 216, 0.51);
}
</style>
